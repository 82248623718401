@use "sass:math";:root {
    --theme-color: #ff9232;
    --theme-color2: #ff9d2d;
    --title-color: #387480;
    --body-color: #4d5765;
    --smoke-color: rgba(1, 15, 28, 0.06);
    --smoke-color2: #faf7f2;
    --black-color: #000000;
    --gray-color: #bdbdbd;
    --white-color: #ffffff;
    --light-color: #72849b;
    --yellow-color: #FFF057;
    --success-color: #28a745;
    --error-color: #dc3545;
    --border-color: #e0e0e0;
    --title-font: "Rubik", sans-serif;
    --style-font: "Lobster Two", cursive;
    --body-font: "Roboto", sans-serif;
    --icon-font: "Font Awesome 6 Pro";
    --main-container: 1220px;
    --container-gutters: 24px;
    --section-space: 120px;
    --section-space-mobile: 80px;
    --section-title-space: 65px;
    --ripple-ani-duration: 5s
}

@media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1024px
    }
}


@media (min-width: 1400px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1024px;
    }
}

body {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.4;
    color: #43433F;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Montserrat", sans-serif;
}

.btn-primary {
    background-color: #FF9232;
    color: #fff;
    padding: 8px 20px;
    border: 0;
    border-radius: 20px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 16px;
    &:hover {
        background-color: #FFF057;
        color: #000;
    }
}

.ripple-animation,.play-btn:after,.play-btn:before {
    -webkit-animation-duration: var(--ripple-ani-duration);
    animation-duration: var(--ripple-ani-duration);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: ripple;
    animation-name: ripple
}

@-webkit-keyframes ripple {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0
    }

    30% {
        opacity: 0.4
    }

    100% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        opacity: 0
    }
}

@keyframes ripple {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0
    }

    30% {
        opacity: 0.4
    }

    100% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        opacity: 0
    }
}

.movingX {
    -webkit-animation: movingX 8s linear infinite;
    animation: movingX 8s linear infinite
}

@-webkit-keyframes movingX {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    50% {
        -webkit-transform: translateX(50px);
        transform: translateX(50px)
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes movingX {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    50% {
        -webkit-transform: translateX(50px);
        transform: translateX(50px)
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

.moving {
    -webkit-animation: moving 8s linear infinite;
    animation: moving 8s linear infinite
}

@-webkit-keyframes moving {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    50% {
        -webkit-transform: translateX(-50px);
        transform: translateX(-50px)
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes moving {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    50% {
        -webkit-transform: translateX(-50px);
        transform: translateX(-50px)
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

.jump {
    -webkit-animation: jumpAni 7s linear infinite;
    animation: jumpAni 7s linear infinite
}

@-webkit-keyframes jumpAni {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    40% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes jumpAni {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    40% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

.jump-reverse {
    -webkit-animation: jumpReverseAni 7s linear infinite;
    animation: jumpReverseAni 7s linear infinite
}

@-webkit-keyframes jumpReverseAni {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    50% {
        -webkit-transform: translateY(30px);
        transform: translateY(30px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes jumpReverseAni {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    50% {
        -webkit-transform: translateY(30px);
        transform: translateY(30px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

.spin {
    -webkit-animation: spin 15s linear infinite;
    animation: spin 15s linear infinite
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-webkit-keyframes rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    50% {
        -webkit-transform: rotate(40deg);
        transform: rotate(40deg)
    }

    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    50% {
        -webkit-transform: rotate(40deg);
        transform: rotate(40deg)
    }

    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
}

@-webkit-keyframes animate-positive {
    0% {
        width: 0
    }
}

@keyframes animate-positive {
    0% {
        width: 0
    }
}

.scalein.as-animated {
    --animation-name: scalein
}

.slidetopleft.as-animated {
    --animation-name: slidetopleft
}

.slidebottomright.as-animated {
    --animation-name: slidebottomright
}

.slideinleft.as-animated {
    --animation-name: slideinleft
}

.slideinright.as-animated {
    --animation-name: slideinright
}

.slideinup.as-animated {
    --animation-name: slideinup
}

.slideindown.as-animated {
    --animation-name: slideindown
}

.rollinleft.as-animated {
    --animation-name: rollinleft
}

.rollinright.as-animated {
    --animation-name: rollinright
}

.scalein,.slidetopleft,.slidebottomright,.slideinleft,.slideinright,.slideindown,.slideinup,.rollinleft,.rollinright {
    opacity: 0;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
    -webkit-animation-name: var(--animation-name);
    animation-name: var(--animation-name)
}

.as-animated {
    opacity: 1
}

@-webkit-keyframes slideinup {
    0% {
        opacity: 0;
        -webkit-transform: translateY(70px);
        transform: translateY(70px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes slideinup {
    0% {
        opacity: 0;
        -webkit-transform: translateY(70px);
        transform: translateY(70px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@-webkit-keyframes slideinright {
    0% {
        opacity: 0;
        -webkit-transform: translateX(70px);
        transform: translateX(70px)
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes slideinright {
    0% {
        opacity: 0;
        -webkit-transform: translateX(70px);
        transform: translateX(70px)
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@-webkit-keyframes slideindown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-70px);
        transform: translateY(-70px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes slideindown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-70px);
        transform: translateY(-70px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@-webkit-keyframes slideinleft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-70px);
        transform: translateX(-70px)
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes slideinleft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-70px);
        transform: translateX(-70px)
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@-webkit-keyframes slidebottomright {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100px) translateY(100px);
        transform: translateX(100px) translateY(100px)
    }

    100% {
        -webkit-transform: translateX(0) translateY(0);
        transform: translateX(0) translateY(0)
    }
}

@keyframes slidebottomright {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100px) translateY(100px);
        transform: translateX(100px) translateY(100px)
    }

    100% {
        -webkit-transform: translateX(0) translateY(0);
        transform: translateX(0) translateY(0)
    }
}

@-webkit-keyframes slidetopleft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px) translateY(-100px);
        transform: translateX(-100px) translateY(-100px)
    }

    100% {
        -webkit-transform: translateX(0) translateY(0);
        transform: translateX(0) translateY(0)
    }
}

@keyframes slidetopleft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px) translateY(-100px);
        transform: translateX(-100px) translateY(-100px)
    }

    100% {
        -webkit-transform: translateX(0) translateY(0);
        transform: translateX(0) translateY(0)
    }
}

@-webkit-keyframes scalein {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.3);
        transform: scale(0.3)
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes scalein {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.3);
        transform: scale(0.3)
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@-webkit-keyframes rollinleft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100%) rotate(-120deg);
        transform: translateX(-100%) rotate(-120deg)
    }

    to {
        -webkit-transform: translateX(0) rotate(0deg);
        transform: translateX(0) rotate(0deg)
    }
}

@keyframes rollinleft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100%) rotate(-120deg);
        transform: translateX(-100%) rotate(-120deg)
    }

    to {
        -webkit-transform: translateX(0) rotate(0deg);
        transform: translateX(0) rotate(0deg)
    }
}

@-webkit-keyframes rollinright {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100%) rotate(120deg);
        transform: translateX(100%) rotate(120deg)
    }

    to {
        -webkit-transform: translateX(0) rotate(0deg);
        transform: translateX(0) rotate(0deg)
    }
}

@keyframes rollinright {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100%) rotate(120deg);
        transform: translateX(100%) rotate(120deg)
    }

    to {
        -webkit-transform: translateX(0) rotate(0deg);
        transform: translateX(0) rotate(0deg)
    }
}

.as-btn {
    min-width: 160px;
    position: relative;
    z-index: 2;
    overflow: hidden;
    vertical-align: middle;
    display: inline-block;
    border: none;
    text-align: center;
    background-color: var(--theme-color);
    color: var(--white-color);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    padding: 12px 35px;
    border-radius: 999px;
    -webkit-transition: background-color 0.25s linear, color 0.4s ease-in-out;
    transition: background-color 0.25s linear, color 0.4s ease-in-out;
    &.as-btn-large {
        width: 100%;
        max-width: 232px;
    }
}

.as-btn:before {
    content: "";
    position: absolute;
    height: 100%;
    bottom: 0;
    left: 0;
    width: 0;
    background-color: var(--title-color);
    z-index: -1;
    -webkit-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    border-radius: inherit
}

.as-btn:hover,.as-btn.active {
    color: var(--white-color);
    background-color: var(--title-color)
}

.as-btn:hover::before,.as-btn.active::before {
    width: 100%
}

.as-btn.style2 {
    background-color: var(--yellow-color);
    color: var(--title-color);
    box-shadow: none;
}

.as-btn.style2:hover {
    color: var(--white-color);
    background-color: var(--theme-color)
}

.as-btn.style2:hover:before {
    background-color: var(--theme-color)
}

.as-btn.style3:hover {
    color: var(--title-color);
    background-color: var(--yellow-color)
}

.as-btn.style3:hover:before {
    background-color: var(--yellow-color)
}

.as-btn.style4 {
    background-color: var(--white-color);
    color: var(--title-color)
}

.as-btn.style4:hover {
    color: var(--white-color);
    background-color: var(--theme-color)
}

.as-btn.style4:hover:before {
    background-color: var(--theme-color)
}

.as-btn.style5 {
    background-color: var(--title-color);
    color: var(--white-color)
}

.as-btn.style5:hover {
    color: var(--title-color);
    background-color: var(--yellow-color)
}

.as-btn.style5:hover:before {
    background-color: var(--yellow-color)
}

.as-btn.style6 {
    background-color: transparent;
    border: 1px solid var(--yellow-color);
    color: var(--yellow-color)
}

.as-btn.style6:hover {
    color: var(--title-color);
    background-color: var(--yellow-color)
}

.as-btn.style6:hover:before {
    background-color: var(--yellow-color)
}

.styled-checkbox {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it

    &+label {
        position: relative;
        cursor: pointer;
        padding: 0 0 0 30px;
    }

    // Box.
    &+label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 24px;
        height: 24px;
        background: #DDDDD7;
        border: 1px solid #F5F5EF;
        border-radius: 5px;
    }

    // Box hover
    /*
    &:hover+label:before {
        background: #f35429;
    }
    */

    // Box focus
    &:focus+label:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    // Box checked
    &:checked+label:before {
        background: #387480;
    }

    // Disabled state label.
    &:disabled+label {
        color: #b8b8b8;
        cursor: auto;
    }

    // Disabled box.
    &:disabled+label:before {
        box-shadow: none;
        background: #ddd;
    }

    // Checkmark. Could be replaced with an image
    &:checked+label:after {
        content: '';
        position: absolute;
        left: 7px;
        top: 11px;
        background: white;
        width: 2px;
        height: 2px;
        box-shadow:
            2px 0 0 white,
            4px 0 0 white,
            4px -2px 0 white,
            4px -4px 0 white,
            4px -6px 0 white,
            4px -8px 0 white;
        transform: rotate(45deg);
    }
}

@media(min-width:768px) {
    /* Modify this based on column def */
    .custom-gutter {
        margin-right: -4rem;
        margin-left: -4rem;
        >[class*='col-'] {
            padding-right: 4rem;
            padding-left: 4rem;
        }
    }

}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-toggler {
        display: block;
    }
}