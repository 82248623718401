.panel {
    .section-title {
        h2 {
            margin-bottom: 40px;
        }
        p {
            font-size: 18px;
        }
    }
    .panel-text {
        width: 100%;
        max-width: 502px;
        margin: 0 auto;
        text-align: center;
        p {
            font-size: 18px;
            color: #5F5F5F;
        }
    }
    .panel-content {
        padding: 45px 0;
    }
}