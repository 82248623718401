.wave-container {
    height: 20vh;
    background-color: #ffffff;
    position: relative;
}

.wave-container::before {
    content: "";
    width: 100%;
    height: 152px;
    position: absolute;
    bottom: -0.3%;
    left: 0;
    background-size: auto;
    background-repeat: repeat no-repeat;
    background-position: 31vw bottom;
    background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 1200 88' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 1C19.5523 2 32.2291 3 51.5 6C104.5 12 200 28 300 43C400 58 500 73 600 67C700 61 800 35 900 20C987.526 3 1085.36 -1 1150 0C1169.54 -1 1180.49 0 1200 1V88H1150C1100 88 1000 88 900 88C800 88 700 88 600 88C500 88 400 88 300 88C200 88 100 88 50 88H0V1.98128Z' fill='%23f5f5ef'/></svg>");
}

@media(max-width:850px) {
    .wave-container::before {
        height: 78.5px
    }
}

.icon-box {
    display: flex;
    align-items: center;
    margin-bottom: 36px;
    .icon {
        width: 26%;
    }
    .text {
        width: 74%;
        p {
            font-family: "Montserrat", sans-serif;
            font-size: 18px;
            line-height: 22px;
            margin: 0;
        }
    }
    
}
