.solutions {
    .button {
        padding-top: 10px;
    }
    .text {
        width: 100%;
        max-width: 740px;
        margin: 0 auto;
        padding-top: 50px;
        p {
            font-size: 20px;
            font-weight: 300;
            color: #fff;
        }
    }
}

.solutions-content {
    padding-top: 100px;
    text-align: center;
}

@media (min-width: 768px) {
    .solutions {
        .desktop {
            display: block;
        }
        .mobile {
            display: none;
        }
    }
}

@media (max-width: 767px) {
    .solutions {
        .desktop {
            display: none;
        }
        .mobile {
            display: block;
        }
    }
}