.header {
    background: #F5F5EF;
    &.sticky{           
        .navbar{
            position: fixed;            
        }
    }
    .container-xl {
        padding-left: 20px;
        padding-right: 20px;
    }
    .navbar {
        position: absolute;
        padding: 25px 8px;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
    }
    .btn-link {
        margin-right: 20px;
        color: #5EC1D6;
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        font-weight: 700;
        text-decoration: none;
        padding: 5px 30px;
    }
}

.navbar-toggler {
    border: 0;
    &:focus {
        box-shadow: none;
    }
}

.hamburger {
    position: relative;
    padding: 10px 28px 16px 0px;
    span {
        cursor: pointer;
        border-radius: 1px;
        height: 3px;
        width: 28px;
        background: #43433F;
        position: absolute;
        display: inline-block;
        content: '';

        &:before,
        &:after {
            cursor: pointer;
            border-radius: 1px;
            height: 3px;
            width: 28px;
            background: #43433F;
            position: absolute;
            left: 0;
            display: inline-block;
            content: '';
        }

        &:before {
            top: -7px;
        }

        &:after {
            bottom: -7px;
        }

        -webkit-transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
        -moz-transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
        -o-transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);

        /* easeInOutCubic */

        &:before,
        &:after {
            -webkit-transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
            -moz-transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
            -o-transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);

            /* easeInOutCubic */
        }
    }

    &.active span {
        background-color: transparent;

        &:before,
        &:after {
            top: 0;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: translateY(-10px) rotate(-45deg);
            top: 10px;
        }
    }
}

.sidemenu-wrapper {
    position: fixed;
    z-index: 99999;
    right: 0;
    top: 0;
    height: 100%;
    width: 0;
    background-color: rgba(94, 193, 214, 0.65);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all ease 0.8s;
    transition: all ease 0.8s;

    .closeButton {
        display: inline-block;
        border: 2px solid;
        width: 50px;
        height: 50px;
        line-height: 50px;
        font-size: 24px;
        padding: 0;
        position: absolute;
        top: 20px;
        right: 20px;
        background-color: var(--white-color);
        border-radius: 50%;
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0);
        -webkit-transition: all ease 0.4s;
        transition: all ease 0.4s;

        &:hover {
            color: var(--theme-color);
            border-color: var(--theme-color);
            -webkit-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: rotate(90deg);
        }
    }

    .sidemenu-content {
        background-color: var(--white-color);
        width: 360px;
        margin-left: auto;
        padding: 70px 20px 80px 20px;
        height: 100%;
        overflow: scroll;
        position: relative;
        right: -500px;
        cursor: auto;
        -webkit-transition-delay: 1s;
        transition-delay: 1s;
        -webkit-transition: right ease 1s;
        transition: right ease 1s;
        box-shadow: 0 0 5px 0 rgba(0,0,0,.1);
        -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,.1);
        -moz-box-shadow: 0 0 5px 0 rgba(0,0,0,.1);

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar {
            width: 2px;
            background-color: #F5F5F5;
        }
    }

    .widget {
        padding: 0;
        border: none;
        background-color: transparent;
    }

    .sidemenu-title {
        h3 {
            font-size: 18px;
            font-weight: 600;
        }
    }

    .menu-block {
        border-top: 1px solid #C4C4BF;
        padding: 16px 18px;
        h4 {
            font-size: 18px;
            font-weight: 600;
        }
        ul {
            list-style-type: none;
            padding: 0;
            li {
                margin-bottom: 10px;
            }
        }
        .menu-link {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #43433F;
            text-decoration: none;
            img {
                margin-right: 20px;
            }
            &:hover {
                color: #5EC1D6;
            }
        }
    }

    &.show {
        opacity: 1;
        visibility: visible;
        width: 100%;
        -webkit-transition: all ease 0.8s;
        transition: all ease 0.8s;

        .sidemenu-content {
            right: 0;
            opacity: 1;
            visibility: visible;
        }
    }
}
