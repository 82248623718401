.stories {
    overflow: hidden;
    padding-bottom: 50px;
    .image {
        margin-bottom: 50px;
    }
}

.testimonial-carousel {
    .owl-stage-outer {
        display: table;
    }
}

.card-testimonial {
    background: #DFF3F7;
    border: 0;
    border-radius: 20px;
    padding: 26px 37px;
    .card-header {
        border: 0;
        background: transparent;
        padding: 0;
    }
    .card-image {
        width: 106px;
        height: 106px;
        border-radius:  100%;
        background: #AFE0EB;
        margin:  0 auto 28px;
        overflow: hidden;
    }
    .card-body {
        padding: 16px 0;
    }
    .card-title, 
    .card-subtitle {
        font-size: 18px;
        color: #43433F;
    }
    .card-text {
        font-size: 18px;
        color: #43433F;
    }
}