.accordion-faqs {
    background-color: #F5F5EF;
    margin-bottom: 30px;
    .accordion-item {
        background: transparent;
        border-width: 0 0 1px 0;
        border-color: #ACACA7;
    }
    .accordion-button {
        font-family: "Montserrat", sans-serif;
        font-size: 18px;
        font-weight: 500;
        color: #43433F;
        background: transparent;
        padding: 21px 20px;
        &:not(.collapsed) {
            color: #43433F;
            background-color: #DFF3F7;
        }
        &:focus {
            box-shadow: none;
        }
    }
}