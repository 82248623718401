.section{
    &.hero{
        padding-top: 100px;
        padding-bottom: 50px;
        overflow: hidden;
    }
    &.methods{
        padding-bottom: 56px;
        .section-title {
            margin-bottom: 46px;
        }
        .section-content {
            background: #F5F5EF;
        }
    }
    &.solutions{
        .section-title {
            background: #5EC1D6;
            margin: 0;
            padding-bottom: 50px;
            h2 {
                color: #ffffff;
            }
        }
        .section-content {
            background: rgb(94,193,214);
            background-image: url('../images/solutions-bg.svg'), linear-gradient(180deg, rgba(94,193,214,1) 0%, rgba(91,187,207,1) 48%, rgba(56,116,128,1) 52%, rgba(56,116,128,1) 100%);
            background-size: auto;
            background-repeat: no-repeat;
            background-position: top center;
            position: relative;
            padding: 30px 0 70px;
            .background {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
            }
        }
    }
    &.faqs{
        .section-content {
            padding-bottom: 30px;
        }
        .section-title {
            background: #387480;
            padding: 57px 20px;
            border-top-right-radius: 20px;
            border-top-left-radius: 20px;
            margin-bottom: 0;
            h2 {
                color: #ffffff;
                margin: 0;
            }
        }
        .button {
            margin-bottom: 50px;
        }
    }
    &.panel {
        .section-content {
            background: #F5F5EF;
            padding-bottom: 100px;
        }
        .section-title {
            padding-top: 10px;
        }
    }
    &.because {
        .section-content {
            background: #F5F5EF;
            padding-bottom: 60px;
        }
    }
    &.works {
        .section-content {
            padding-bottom: 60px;
        }
        .section-title {
            img {
                margin-bottom: 30px;
            }
        }
    }
}
.section-title {
    margin-bottom: 25px;
    text-align: center;
    padding-top: 40px;
    margin-bottom: 40px;
    h2 {
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        font-size: 24px;
        line-height: 1;
        color: #43433F;
    }
}

.block-box {
    width: 100%;
    max-width:  880px;
    margin: 0 auto;
}

.hero-title {
    font-family: "Preahvihear", sans-serif;
    font-size: 24px;
    color: #43433F;
    margin-bottom: 40px;
}

.hero-image {
    text-align: center;
    .image {
        width: 100%;
    }
}

.hero-text {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    padding: 0 15px;
    h3 {
        font-family: "Montserrat", sans-serif;
        font-size: 20px;
        font-weight: 600;
    }
    p {
        font-size: 18px;
        line-height: 22px;
    }
}