.content {
    background: #F5F5EF;
    &.white {
        background: #fff;
    }
}

.content-logo {
    margin-bottom: 76px
}

.create-account-content {
    text-align: center;
    padding-top: 30px;
    .image {
        margin-bottom: 24px;
    }
    h2 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 18px;
    }
}

.create-account-form {
    .form-box {
        width: 100%;
        max-width: 306px;
        margin: 0 auto;
    }    
    form {
        margin-bottom: 10px;
    }
    .form-group {
        margin-bottom: 22px;
        position: relative;
        .icon {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 20px;
            &.icon-email {
                font-size: 18px;
            }
        }
        &.form-group-icon {
            .form-control {
                padding-right: 40px;
            }
        }
    }
    .form-control, .form-select {
        padding: 10px 0.75rem;
        border: 0;
        border-radius: 10px
    }
    .check-box {
        padding: 0;
        width: 100%;
        max-width: 230px;
        margin: 0 auto;
    }
    .social-network {
        padding-top: 10px;
        ul {
            display: flex;
            list-style-type: none;
            align-items: center;
            justify-content: center;
            padding: 0;
            li {
                margin: 0 10px;
            }
        }
    }
    h3 {
        font-size: 18px;
        color: #43433F;
        font-weight: 600;
        margin-bottom: 20px;
    }
}