@font-face {
  font-family: 'icomoon-star';
  src:  url('../fonts/icomoon-star.eot?9qbd5d');
  src:  url('../fonts/icomoon-star.eot?9qbd5d#iefix') format('embedded-opentype'),
    url('../fonts/icomoon-star.ttf?9qbd5d') format('truetype'),
    url('../fonts/icomoon-star.woff?9qbd5d') format('woff'),
    url('../fonts/icomoon-star.svg?9qbd5d#icomoon-star') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-star' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-apple-square:before {
  content: "\e900";
}
.icon-email:before {
  content: "\e901";
}
.icon-facebook:before {
  content: "\e902";
}
.icon-facebook-square:before {
  content: "\e903";
}
.icon-google-square:before {
  content: "\e904";
}
.icon-instagram:before {
  content: "\e905";
}
.icon-linkedin:before {
  content: "\e906";
}
.icon-visibility:before {
  content: "\e907";
}
.icon-back-arrow:before {
  content: "\e908";
}
.icon-pencil:before {
  content: "\e909";
}
