@media only screen and (max-width: 767px) {
    body {
        font-size: 14px;
    }
    .as-btn {
        min-width: 134px;
    }
    .sidemenu-wrapper {
        top: 90px;
        .closeButton {
            display: none;
        }
        .sidemenu-content {
            padding-top: 30px;
        }
    }

    .section  {
        &.hero {
            padding-bottom: 30px;            
        }
        &.hero-partners {
            .hero-title {
                margin-bottom: 54px;
            }
            .btn {
                margin-bottom: 48px;
            }
            .hero-image {
                margin-bottom: 10px;
            }
            .hero-text {
                padding: 35px 0 0;
                margin-bottom: 30px;
            }
        }
        &.methods {
            .section-title {
                padding-top: 60px;
            }
        }
        &.solutions {
            .section-content {
                background-position: center;
            }
        }
        &.works-partners {
            .section-content {
                padding-top: 60px;
            }
            .section-title {
                margin-bottom: 54px;
            }
        }
    }

    .hero-title {
        line-height: 3rem;
        margin-bottom: 25px;
        padding: 0 20px;
    }

    .hero-btn {
        margin-bottom: 25px;
    }

    .hero-image {
        img {
            width: 110% !important;
        }
    }

    .hero-text {
        padding-top: 35px;
        h3 {
            margin-bottom: 28px;
        }
    }

    .section-title {
        h2 {
            font-size: 22px;
        }
    }

    .block-box {
        padding-left: 15px;
        padding-right: 15px;
    }

    .icon-box {
        margin-bottom: 18px;
        .icon {
            width: 30%;
        } 
        .text {
            width: 70%;
            p {
                font-size: 16px;
            }
        }
    }

    .section {
        &.methods {            
            .section-title{ 
                margin-bottom: 34px;
            }
            .button {
                padding-top: 20px;
            }
        }
        &.faqs {
            .section-title {
                padding: 39px 20px;
            }            
        }
        &.share-love {
            .image {
                margin-bottom: 20px;
            }
        }
        &.statistics {
            .section-title {
                margin-bottom: 47px;
            }
        }
    }

    .solutions {
        .text p {
            font-size: 16px;
        }
    }

    .testimonial-carousel {
        padding-left: 0;
        padding-right: 0;
    }

    .card-testimonial { 
        flex-direction: row;
        padding: 26px 25px;
        .card-header {  
            width: 30%;
            text-align: center;
        }
        .card-body {
            width: 70%;
            padding-left: 20px;
        }
        .card-image {
            width: 70px;
            height: 70px;
            margin-bottom: 18px;
        }
        .card-title, 
        .card-subtitle {
            font-size: 14px;            
        }
        .card-text {
            font-size: 16px;
            line-height: 1.2;
        }
    }

    .accordion-faqs {
        margin-bottom: 60px;
        .accordion-button {
            font-size: 16px;
        }
        .accordion-body {
            font-size: 14px;   
        }
    }

    .share-love .share-text p {
        font-size: 16px;
    }

    // inner

    .content {
        .go-back, .go-edit {
            font-size: 16px;
        }
    }

    .create-account {
        .content-logo {
            margin-bottom: 0;
        }
        .image {
            margin-bottom: 10px;
            img {
                max-width: 214px;
            }
        }
        &.partners {
            .content-logo {
                margin-bottom: 12px;
            }
            .create-account-content {
                .image {
                    margin-bottom: 15px;
                }                
            }
        }
    }
    .create-account-content {
        h2 {
            font-size: 20px;
        }
    }
    .page-heading h2 { 
        font-size: 20px
    }

    .happy-message {
        padding: 0;
        h3 {
            font-size: 16px;
            margin-bottom: 27px;
        }
        .image {
            margin-bottom: 32px;
        }
        .app-buttons {
            margin-bottom: 20px;
        }
        .text-divider {
            font-size: 16px
        }
        .text-box {
            padding-top: 12px;
            p {
                font-size: 18px;
            }
            .button {
                padding-top: 20px;
            }
        }
    }    

    .content-box { 
        padding: 50px 0;
        &.terms {
            padding: 50px 26px;
        }
        &.gift-message {
            .page-heading {
                padding: 0 40px;
                h2 {
                    margin-bottom: 45px;
                }
                p {
                    font-size: 16px;
                    line-height: 1.2;
                }
            }
            .message-text {
                p {
                    font-size: 18px;
                    margin-bottom: 28px;
                }
            }
        }
    }

    .create-account-form .form-box {
        max-width: 296px;
    }    

    .content-tabs .nav-tabs .nav-link {
        margin: 0 10px;
    }

    .resources-card {
        .card-header {
            padding: 30px 20px 10px 20px;
        }
        .card-body {
            padding: 30px 32px;
        }
    }

    .resource-box {
        margin-bottom: 23px;
        .text {
            h4, p {
                font-size: 16px;
            }
            h4 {
                margin-bottom: 10px;
            }
            p {
                line-height: 1.2;
            }
        }
    }

    .statistics-content {
        padding: 0 24px 60px;
        p {
            font-size: 16px;
            line-height: 1.2;
        }
    }

    .cloud-box {
        padding: 60px 0;
        margin-bottom: 50px;
        background-size: 25%;
        img {
            width: 140px;
        }
    }

    .validate-email-box {
        .image {
            max-width: 77%;
            margin-bottom: 60px;
        }
        .text-box {
            padding: 0 30px;
        }
    }

    .work-with-us {
        .work-content {
            padding: 41px 0 41px;
            .image {
                margin-bottom: 44px;
            }
            .text-box {
                padding: 0 20px;
                p {
                    font-size: 16px;
                    margin-bottom: 40px;
                }
            }
        }
    }

    .footer { 
        .footer-content {
            padding-left: 20px;
            padding-right: 20px;
        }
        .footer-logo-social {
            flex-direction: row;
            justify-content: space-between;
            border-left: 0;
            border-top: 1px solid #387480;
            padding-left: 0;
            padding-top: 30px;
        }
    }

}
