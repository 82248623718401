.share-love {
    .section-title {
        margin-top: 47px;        
    }
    .button {
        margin-bottom: 32px;
    }
    .share-text {
        width: 100%;
        max-width: 336px;
        margin: 0 auto;
        p {
            font-size: 18px;
        }
    }
}