.content {
    .app-buttons {
        display: flex;
        align-items: center;
        justify-content: center;

        .btn-link {
            display: inline-block;
            background: #387480;
            border-radius: 23px;
            padding: 10px 26px;
            margin: 0 6px;
        }
    }
    .go-back, .go-edit {
        font-size: 26px;
        text-decoration: none;
        color: #5EC1D6;
    }
}

.content-box {
    width: 100%;
    max-width: 892px;
    margin: 0 auto;
    background: #fff;
    border-radius: 25px;
    box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.15);
    -webkit-box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.15);
    margin-top: 50px;
    padding: 54px 30px;

    &.transparent {
        background: none;
        box-shadow: none;

        .text-box {
            width: 100%;
            max-width: 434px;
            margin: 0 auto;
        }
    }
}

.page-heading {
    text-align: center;
    h2 {
        font-size: 24px;
        font-weight: 600;
        color: #43433F;
        margin-bottom: 30px;
    }
}

.content-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    .btn {
        margin: 0 10px;
    }
}

.validate-email-box {
    padding-top: 66px;
    .text-box {
        p {
            font-size: 18px;
        }
    }
}

.solution-wrapper {
    width: 100%;
    max-width: 766px;
    margin: 0 auto 30px;
    padding-top: 60px;
}

.solution-card {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-grow: 1;
    flex-basis: 0;
    width: 100%;
    max-width: 340px;
    margin: 0 auto 25px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
    border: 0;
    border-radius: 25px;
    .card-image,
    .card-body {
        flex: 1;
    }
    h4 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 14px;
    }
    p {
        margin: 0;
        font-size: 16px;
    }
}

.solutions-lines {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
}

.content-tabs {
    .nav-tabs {
        border: 0;

        .nav-link {
            border: 0;
            font-family: "Montserrat";
            font-size: 16px;
            color: #43433F;
            padding: 12px 0;
            margin: 0 20px;

            &.active {
                border-bottom: solid 2px #5EC1D6;
                background-color: transparent;
            }
        }
    }

    .tab-content {
        width: 100%;
        max-width: 830px;
        margin: 0 auto;
        padding-top: 40px;
    }
}

.subscription-card {
    width: 100%;
    max-width: 340px;
    margin: 0 auto 25px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
    border: 0;
    border-radius: 25px;
    .card-header {
        position: relative;
        height: 150px;
        padding: 20px 18px;
        border: 0;
        padding: 25px 18px 20px;
        h3,
        p {
            position: relative;
            z-index: 1;
        }
        h3 {
            font-size: 18px;
            font-weight: 600;
            color: #fff;
        }
        p {
            font-size: 16px;
            color: #fff;
            max-width: 142px;
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .card-body {
        padding: 0 10px 16px;
        td {
            font-size: 14px;
            color: #43433F;
            border: 0;
            &.label {
                font-weight: 700;
            }
        }

        .hidden-content {
            display: none;
        }

        .bnt-link {
            font-size: 14px;
            color: #5EC1D6;
        }
    }
}

.happy-message {
    padding: 56px 0 60px;
    h3 {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 22px;
    }
    .app-buttons {
        margin-bottom: 50px;
    }
    .text-divider {
        font-size: 18px;
        margin-bottom: 32px;
    }
    .btn-link {
        font-weight: 700;
        font-size: 18px;
        color: #4B9AAB;
        text-decoration: none;
    }
}

.therapy-card {
    width: 100%;
    max-width: 792px;
    margin: 0 auto;
    border: 0;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
    border-radius: 25px;
    .card-header {
        height: 150px;
        background: #387480;
        padding: 50px 30px 10px 50px;
        border-top-right-radius: 25px;
        border-top-left-radius: 25px;
        position: relative;
        h3, p {
            color: #fff;
        }
        h3 {
            font-size: 18px;
            font-weight: 600;
        }
        .image {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    .card-body {
        padding: 50px 30px;
        .text-content {
            width: 100%;
            max-width: 680px;
            margin: 0 auto;
            text-align: center;
            p {
                margin-bottom: 38px;
                &.price {
                    font-size: 24px;
                    font-weight: 600;
                    margin-bottom: 32px;
                }
            }
        }
        
    }

}

:root {
    --switches-bg-color: #fff;
    --switches-label-color: #43433F ;
    --switch-bg-color: #5EC1D6;
    --switch-text-color: #fff ;
}

.switches-container {
    width: 100%;
    max-width: 298px;
    position: relative;
    display: flex;
    padding: 0;
    position: relative;
    background: var(--switches-bg-color);
    line-height: 42px;
    border-radius: 999px;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid #5EC1D6;
    margin-bottom: 50px;

    input {
        visibility: hidden;
        position: absolute;
        top: 0;
    }

    label {
        width: 50%;
        padding: 0;
        margin: 0;
        text-align: center;
        cursor: pointer;
        color: var(--switches-label-color);
    }
}

.switch-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    z-index: 3;
    transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);

    /* transition: transform 1s; */
}

/* switch box highlighter */

.switch {
    border-radius: 999px;
    background: var(--switch-bg-color);
    height: 100%;

    div {
        width: 100%;
        text-align: center;
        opacity: 0;
        display: block;
        color: var(--switch-text-color);
        transition: opacity 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0.125s;
        will-change: opacity;
        position: absolute;
        top: 0;
        left: 0;
    }
}

/* switch box labels
      - default setup
      - toggle afterwards based on radio:checked status 
  */

/* slide the switch box from right to left */

.switches-container input {
    &:nth-of-type(1):checked~.switch-wrapper {
        transform: translateX(0%);
    }

    &:nth-of-type(2):checked~.switch-wrapper {
        transform: translateX(100%);
    }

    &:nth-of-type(1):checked~.switch-wrapper .switch div:nth-of-type(1),
    &:nth-of-type(2):checked~.switch-wrapper .switch div:nth-of-type(2) {
        opacity: 1;
    }
}

.resources-card {
    background: #fff;
    border: 0;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
    border-radius: 25px;
    margin-bottom: 80px;
    .card-header {
        height: 152px;
        background: #387480;
        padding: 50px 30px 10px 50px;
        border-top-right-radius: 25px;
        border-top-left-radius: 25px;
        position: relative;
        img {
            position: absolute;
            right: 0;
            bottom: 0;
        }
        .card-header-text {
            width: 100%;
            max-width: 60%;
        }
        h3, p {
            color: #fff;
            position: relative;
            z-index: 2;
        }
        h3 {
            font-size: 18px;
            font-weight: 600;
        }
    }
    .card-body {
        padding: 50px 54px;
    }
}

.resource-box {
    display: flex;
    margin-bottom: 50px;
    .icon {
        width: 26%;
    }
    .text {
        width: 74%;
        h4 {
            font-size: 20px;
            font-weight: 600;
            color: #43433F;
            margin-bottom: 15px;
        }
        p {
            font-family: "Montserrat", sans-serif;
            font-size: 18px;
            line-height: 22px;
        }
    }
    
}

.payment-wrapper {
    width: 100%;
    max-width: 802px;
    margin: 0 auto;
    padding-top: 26px;
    .legend {
        font-size: 20px;
        font-weight: 400;
        color: #43433F;
        margin-bottom: 20px;
    }
}

.star-form {
    width: 100%;
    max-width: 295px;
    margin: 0 auto;
    .form-group {
        margin-bottom: 20px;
    }
    .form-control {
        padding: 10px 0.75rem;
        border: 0;
        border-radius: 10px
    }
}

.payment-summary {
    width: 100%;
    max-width: 295px;
    margin: 0 auto;
    background: #fff;
    border-radius: 10px;
    padding: 13px 16px;
    .table {
        tr {
            td {
                border: 0;
                font-size: 18px;
                padding: 10px 8px;
                &.amount {
                    font-weight: 600;
                }
            }
        }
    }
}

.cloud-box {
    background-image: url("../images/cloud-right.svg"), url("../images/cloud-left.svg");
    background-position: right top, left bottom;
    background-repeat: no-repeat, no-repeat;
    padding: 50px 0 40px;
    text-align: center;
    margin-bottom: 20px;
}

.work-with-us {
    .page-heading {
        padding-top: 60px;
    }
    .work-content {
        padding: 56px 0 60px;
        p {
            font-size: 18px
        }
    }
}

.input-file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text {
        padding: 10px .75rem;
    }
}

.file-wrap {
	display: inline-block;
	position: relative;
	input {
		position: absolute;
		top: 0; bottom: 0; left: 0; width: 100%;
		opacity: 0;
	}
    .btn {
        padding: 0;
        cursor: pointer;
    }
}

.btn-checkbox {
    margin-bottom: 18px;
    label {
        line-height: 34px;
        width: 100%;
        height: 34px;
        cursor: pointer;
        span {
            text-align: center;
            display: block;
            background: #fff;
            border-radius: 999px;
        }
        input {
            position: absolute;
            display: none;
            color: #fff !important;

            +span {
                color: #43433F;
            }
        }
    }

    input:checked+span {
        color: #ffffff;
        background-color: #5EC1D6;
    }
}