.statistics-content {
    text-align: center;
    p {
        font-size: 18px;
        margin-bottom: 30px;
    }
    .image {
        margin-bottom: 40px;
    }
    .button {
        padding-top: 30px;
    }
}