.tree {
    .section-title {
        h2 {
            margin-bottom: 30px;
        }
    }
}

.tree-form {
    width: 100%;
    max-width: 418px;
    margin: 0 auto 50px;
    .form-control {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border: 0;
        background: #F5F5EF;
    }
    .btn {
        background: #FF9232;
        color: #fff;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        font-weight: 600;
    }
}