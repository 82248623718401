.footer {
    .footer-content {
        background: #1C3A40;
        padding: 30px 0 20px;
    }
    .link {
        display: flex;
        align-items: center;
        color: #fff;
        text-decoration: none;
        margin-bottom: 20px;
        img {
            margin-right: 20px
        };
    }
    h4 {
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 10px;
    }
    .btn-link {
        display: inline-block;
        background: #fff;
        border-radius: 16px;
        padding: 10px 15px;
    }

    .app-buttons {
        margin-bottom: 30px;
    }

    .footer-logo-social {
        display: flex;
        flex-direction: column;
        border-left: 1px solid #387480;
        padding-left: 30px;
    }

    .footer-logo {
        h5, p {
            color: #fff;
        }
        h5 {
            font-size: 22px;
            font-weight: 400;
        }
    }    

    .footer-social {
        ul {
            list-style-type: none;
            padding: 0;
            li {
                display: inline-block;
                &:not(:first-child) {
                    margin-left: 20px;
                }
            }
        }
    }
    
}
